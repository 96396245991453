import { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { TheFirstStepSchema } from "app/const/yup";
import { FastField, Form, Formik } from "formik";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import InputField from "share/customField/inputField";
import SelectField from "share/customField/selectField";
import { DEFALT_OPTIONS } from "share/customField/selectField/options";
import "./styles.scss";

const ButtonSubmit = styled(Button)`
  color: #fff;
  font-size: 17px;
  background-image: linear-gradient(rgb(255, 0, 191), #8420be);
  :hover {
    color: #fff;
    background-image: linear-gradient(#8420be, rgb(255, 0, 191));
  }
`;

function RegisterForm(props) {
  const step = 0;
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(599);
  const { title, ticket } = props;
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    email: "",
    phone: "",
    dichvu:
      props.type === "lrbbl"
        ? "KHÓA HỌC TRỊ LIỆU TÂM LÝ ĐẸP & ĐƯỢC YÊU"
        : props.type === "lrworkshop"
          ? "WORKSHOP BLOOM AND BALANCE"
          : props.type === "lrlivestream"
            ? "BẠN XỨNG ĐÁNG ĐƯỢC YÊU THƯƠNG"
            : "TRỊ LIỆU TÂM LÝ CHUYÊN SÂU 1:1",
    loaive: "General",
    songuoidicung: 0,
    giatien: "",
  });

  const handleRegister = async (data) => {
    props?.setStep(1);
    props?.setData(data);
    data.giatien = calculatedPrice(data.songuoidicung);
    const parsedParams = props.parsedParams ? props.parsedParams : {};
    setLoading(true);
    data["tags"] = ["LeonardoRossi"];
    data["import_by_tag_name"] = true;
    const finalData = {
      ...data,
      ...parsedParams,
    };
    console.log("finalData", finalData);
    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data: finalData,
    })
      .then((response) => {
        setLoading(false);
        console.log("response", response);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const calculatedPrice = (totalPeople) => {
    const basePrice = 599;
    const discountedPrice = 499;

    const calculatedPrice = totalPeople === 0 ? basePrice : discountedPrice * (Number(totalPeople) + 1);
    setTotalPrice(calculatedPrice);
    return calculatedPrice;
  };

  const validationSchema = Yup.object().shape(TheFirstStepSchema);

  const renderConditionalFields = () => {
    switch (props?.type) {
      case "lrcourse":
        return (
          <div className="col-12 col-md-6">
            <FastField
              name="loaive"
              component={SelectField}
              label="Loại vé"
              className="w-100 mb-4"
              options={DEFALT_OPTIONS.loaive}
            />
          </div>
        );

      case "lrworkshop":
        return (
          <>
            <div className="col-12 col-md-6">
              <FastField
                name="songuoidicung"
                component={InputField}
                label="Số người đi cùng"
                className="w-100 mb-4"
                placeholder="Nhập số người đi cùng bạn"
              />
            </div>
            <div className="col-12 col-md-6">
              <FastField
                name="giatien"
                readOnly={true}
                component={InputField}
                disabled={true}
                label="Khi tham gia từ 2 người , giá chỉ còn 499.000 VND/người"
                className="w-100 mb-2 mb-md-4"
              />
            </div>
          </>
        );

      default:
        return (
          <></>
        );
    }
  };


  const RenderUI = (step) => {
    switch (step) {
      case 0:
        return (
          <div
            className="registerForm row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="frame-bg">
              <h3 className=" pt-2  header-title">{title}</h3>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
              enableReinitialize
            >
              {(formikProps) => {
                const { values, errors, touched } = formikProps;
                // console.log({ values, errors, touched });

                return (
                  <Form className="row mt-4 py-3 form-body" style={{}}>
                    <div className="col-12 col-md-6">
                      <FastField
                        name="first_name"
                        component={InputField}
                        label="Tên đầy đủ"
                        placeholder="Nhập tên"
                        className="w-100 mb-4"
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <FastField
                        name="email"
                        component={InputField}
                        label="Email"
                        placeholder="Nhập email"
                        className="w-100 mb-4"
                      />
                      {/* {errors?.email && (
                        <p className="text-danger mb-4 ml-2 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root mt-n3 ">
                          {errors.email}
                        </p>
                      )} */}
                    </div>

                    <div className="col-12 col-md-6">
                      <FastField
                        name="phone"
                        component={InputField}
                        label="Số điện thoại"
                        placeholder="Nhập số điện thoại"
                        className="w-100 mb-4"
                        autocomplete
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <FastField
                        name="dichvu"
                        readOnly={true}
                        component={InputField}
                        label="Dịch vụ"
                        placeholder="Dịch vụ"
                        className="w-100 mb-2 mb-md-4"
                      />
                    </div>
                    {renderConditionalFields()}
                    <div className="col-12 justify-content-center d-flex">
                      <ButtonSubmit type="submit" className="">
                        Đăng ký
                        {loading && <div className="loader ml-1"></div>}
                      </ButtonSubmit>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        );
      case 1:
        return (
          <>
            <div className="OTPContainer">
              <div className="px-5 py-5">
                <div className="row">
                  <h2>CÁM ƠN BẠN </h2>
                  <h4 style={{ textAlign: "center" }}>
                    ĐÃ ĐĂNG KÝ CHƯƠNG TRÌNH
                  </h4>
                </div>
                <div className="row mt-4 text-center">
                  <p> Chúng tôi sẽ sớm liên hệ lại với bạn</p>
                  <p>Liên hệ hotline để được giải đáp thắc mắc</p>
                </div>
              </div>
            </div>
          </>
        );

      default:
        throw new Error("Invalid Screen!!!");
    }
  };

  return RenderUI(step);
}

export default RegisterForm;
